export const tableColumnsTopCreators = [

  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "Role Name",
    accessor: "name",
  },
  
  {
    Header: "Action",
    accessor: "actions",
  },
];
