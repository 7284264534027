/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Select
} from "@chakra-ui/react";
import { message } from "antd";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth1.jpg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { saveRecords,fetchRecords } from "appRedux/actions";
import {GET_ALL_ROLES,CREATE_ADMINER} from '../../../constants/ServerUrl'


function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [formValues, setFormValues] = React.useState({});
  const [loading,isLoading] = useState(false)
  const [roles,setRoles] = useState();
  const handleSignUp = (e)=>{
    e.preventDefault();
   
    saveRecords(CREATE_ADMINER, formValues).then(res => {
        console.log(res)
        if (res.data.status) {
            isLoading(false)
            message.success('Adminer Successfully Created')
            window.location="/auth"
            
        } else {
            // setError(res.error);
            message.warn("Error encountered while creating adminer");
        }
        isLoading(false);
    }).catch(err => {
        console.log(err?.response?.data);
        if(err?.response?.data){
            if(Object.keys(err?.response?.data).length ==1){
                message.error(Object.values(err?.response?.data))
            }
            else if(Object.keys(err?.response?.data).length > 1){
                
                Object.entries(err?.response?.data).map(([field,messages],index)=>{
                    message.error(
                        <div key={index}>
                            {messages.map((message, i) => (
                            <span key={i}>{message}</span>
                        ))}
                        </div>
                    )
                    
                })
            }
        }
       
        isLoading(false);
    });
  }
  React.useEffect(()=>{
    getAllRoles();
  },[])

  const getAllRoles = ()=>{
   
    fetchRecords(GET_ALL_ROLES).then((res) => {
       console.log('result',res.data.roles)
       setRoles(res.data.roles)
       
    }).catch(err => {
        console.log("error",err);
    });
  }
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
        
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Elite Fitness App
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Create an Adminer
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='12px'>
              
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Firstname<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@elite-app.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,firstname:event.target.value})}
            />
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Lastname<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@elite-app.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,lastname:event.target.value})}
            />
             <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@elite-app.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,email:event.target.value})}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(event)=>setFormValues({...formValues,password:event.target.value})}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Role<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select an option"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,role:event.target.value})}
        >
          {roles && roles.length > 0 && roles.map((d,index)=>{
            if(d.name != "user"){
                return <option value={d.name}>{d.name}</option>
            }
            
          })}
        </Select>
           
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={handleSignUp}>
              Create Admin
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            {/* <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text> */}
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
