import { configureStore ,combineReducers,getDefaultMiddleware } from "@reduxjs/toolkit";
import UserDetails from '../reducers/UserDetails'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const allReducer = combineReducers({
    UserDetails:UserDetails
})

const persistedReducer = persistReducer(persistConfig, allReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for DevTools compatibility
    }).concat(/* Other middleware if needed */),
})

export const persistor = persistStore(store)