// Chakra imports
import { Box, Icon, Text, useColorModeValue,PopoverContent,PopoverBody,PopoverTrigger,Popover, Button } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { message } from "antd";
import {MdEdit} from 'react-icons/md'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveRecords } from "appRedux/actions";
import { useSelector } from "react-redux";
import { UPDATE_EXPIRY_DATE } from "constants/ServerUrl";
import moment from "moment";

export default function Information(props) {
  const { title, value,edit,rowId,refresh, ...rest} = props;
  
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  console.log('value',title)
  const [selectedDate, setSelectedDate] = React.useState(new Date('11/06/2024'));
  const [expiryDate,setExpiryDate] = React.useState(value);
  const [loading,isLoading] = React.useState(false)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const handleExtendEdit = () => {
    setIsDatePickerOpen((prevState) => !prevState);
  };
  const handleDateChange = (date) => {
    console.log(date)
    setSelectedDate(date);
    // Update the value prop with the new date
    // you may need to pass a callback function to the parent component
  };
  const handleUpdate = ()=>{
    isLoading(true)
    saveRecords(UPDATE_EXPIRY_DATE, {new_expiry_date:selectedDate,id:rowId,type:edit},token).then(res => {
      
      isLoading(false);
      if (res.status == "Request was successful") {
          message.info('Expiry Date Updated')
          handleExtendEdit()
          setExpiryDate(moment(selectedDate).format('DD-MM-YYYY'))
          refresh()
      } else {
          // setError(res.error);
          message.info("Error encountered while updating the record");
      }
      
  }).catch(err => {

      if(err?.response?.data){
        console.log(err?.response?.data)
          message.error(err?.response?.data?.message)
          
      }
    
      isLoading(false);
  });
  }
  return (
    <Card bg={bg} {...rest}>
      <Box>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
          {title}
        </Text>
        <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
          {expiryDate}
        </Text>
        {edit && (
          <Popover isOpen={isDatePickerOpen} onClose={() => setIsDatePickerOpen(false)}>
            <PopoverTrigger>
              <Icon as={MdEdit} onClick={handleExtendEdit} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                />
                <Button onClick={()=>handleUpdate()}>Update</Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
       
      </Box>
    </Card>
  );
}
