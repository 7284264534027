// AuthRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.UserDetails.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Redirect to="/admin" /> : <Component {...props} />
      }
    />
  );
};

export default AuthRoute;
