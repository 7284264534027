export const tableColumnsTopCreators = [
  {
    Header:'SN',
    accessor:''
  },
  {
    Header: "UserId",
    accessor: "id",
  },
  {
    Header: "First Name",
    accessor: "firstname",
  },
  {
    Header: "Last Name",
    accessor: "lastname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Amount Paid",
    accessor: "coach_amount",
  },
  {
    Header: "Class",
    accessor: "plan_selected",
  },
  
  {
    Header: "Coach Expiry Date",
    accessor: "coach_expiry_date",
  },
  


  {
    Header: "Action",
    accessor: "actions",
  },
];
