/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Input,
  Button,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdSearch,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useSelector,useDispatch } from "react-redux";
import {useHistory} from 'react-router-dom'
import { GET_DASHBOARD_CORE } from "constants/ServerUrl";
import { fetchRecords } from "appRedux/actions";
import { message } from "antd";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

export default function UserReports() {
  const {userdetails} = useSelector((state)=>state.UserDetails)
  const [mount,isMount] = useState(true)
  const history = useHistory()
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [startdate,isStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [users,isUsers] = useState(0);
  const [planpayment,isPlanPayment] = useState(0);
  const [enddate,isEndDate] = useState(new Date());
  const [userwithcoach,isUserwithcoach] = useState(0);
  const [applicationFee,isApplicationFee] = useState(0);
  const [taskSchedule,isTaskSchedule] = useState(0);
  const [assignTask,isAssignTask] = useState(0);
  const [loading,isLoading] = useState(false)
  const [expensess,isExpenses] = useState(0)
  const role_id = userdetails?.user?.roles[0].id;
  React.useEffect(()=>{
    if(userdetails == null){
      history.push('/auth')
    }
  },[])
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  React.useEffect(() => {

    let timeOutId = setTimeout(() => {
        mountedRequests();
    }, 1200);


    return () => {

        clearTimeout(timeOutId);
        isMount(false);

    }
}, []);


  const mountedRequests = () => {
    if (mount) {
        getDashboardCore()
    }

}
const getDashboardCore = (startdate=null,enddate=null)=>{
  fetchRecords(GET_DASHBOARD_CORE+`?startdate=${startdate}&enddate=${enddate}`,token).then((res) => {
     console.log(res.data)
     isLoading(false)
     isUsers(res.data.users);
     isPlanPayment(res.data.planpayment)
     isUserwithcoach(res.data.userWithCoach)
     isApplicationFee(res.data.applicationFee)
     isTaskSchedule(res.data.taskScheduleByUserCount)
     isAssignTask(res.data.assignTask)
     isExpenses(res.data.expenses)
     
  }).catch(err => {
    isLoading(false)
       message.error('Dashboard Core Failed')
      console.log("error",err);
  });
}
const handleDateChange = (date)=>{
  console.log(date)
 isStartDate(date)
}
const handleEndDateChange = (date)=>{
  console.log(date)
 isEndDate(date)
}
const handleFilterDashboard = ()=>{
  
  let startDate = moment(startdate).format('YYYY-MM-DD')
  let endDate = moment(enddate).format('YYYY-MM-DD')
  getDashboardCore(startDate,endDate)
  isLoading(true)
}
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex mb="3" gap={5} justifyContent="flex-end">
             
                <ReactDatePicker
                  selected={startdate}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select a date"
                      _placeholder={{ color: "gray.400" }}
                      _focus={{
                        borderColor: "brand.500",
                        boxShadow: "0 0 0 1px brand.500",
                      }}
                    />
        }
        calendarClassName="chakra-date-picker"
      />
             <ReactDatePicker
                  selected={enddate}
                  onChange={handleEndDateChange}
                  dateFormat="MM/dd/yyyy"
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select a date"
                      _placeholder={{ color: "gray.400" }}
                      _focus={{
                        borderColor: "brand.500",
                        boxShadow: "0 0 0 1px brand.500",
                      }}
                    />
        }
        calendarClassName="chakra-date-picker"
      />
      <Button isLoading={loading}  onClick={()=>handleFilterDashboard()} colorScheme="blue"><Icon as={MdSearch}/></Button>

      


        </Flex>
     
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name={role_id == 7 ? 'Assigned Users' : 'Total Users'}
          value={users}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name={role_id == 7 ? 'Monthly Task Schedules' : 'Plan Payment'}
          
          value={planpayment?.toLocaleString()}
        />
        {role_id != 7 && <MiniStatistics growth='+23%' name='Application Fee' value='$0' /> }
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                {/* <Avatar src={Usa} /> */}
              </FormLabel>
              {/* <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select> */}
            </Flex>
          }
          name={role_id == 7 ? 'Payment Status For The Month' : 'Users With Coach'}
          
          value={userwithcoach?.toLocaleString()}
        />

        {role_id != 7 && <>
          <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                {/* <Avatar src={Usa} /> */}
              </FormLabel>
              {/* <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select> */}
            </Flex>
          }

          name='Expense'
          
          value={expensess?.toLocaleString()}
        />
        </>}
       
      {role_id != 7 &&  <>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value={assignTask}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Schedules'
          value={taskSchedule}
        />
        </>
        }
      </SimpleGrid>
      {
        role_id != 7 &&  
        <>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      </>
      }

      
    
    </Box>

    
  );
}
