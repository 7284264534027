/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
   DrawerHeader, 
   DrawerBody,
   Input,
   Spinner,
   Modal,
   
} from "@chakra-ui/react";


// Custom components

import Banner from "views/admin/checkin/components/Banner";
import TableTopCreators from "views/admin/class/components/TableTopCreators";
import HistoryItem from "views/admin/class/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/checkin/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/class/variables/tableColumnsTopCreators";
import {Alert, message} from 'antd'
import { saveRecords,fetchRecords } from "appRedux/actions";
import {useSelector,useDispatch} from 'react-redux'
import {BLOG_POST, CHECKIN, CLASSES, GET_ALL_PERMISSION, GET_CHECK_IN, STAFF} from '../../../constants/ServerUrl'
import { all } from "axios";
import WelcomeVoice from '../../../assets/sound/welcome-64347.mp3'
import WelcomeSound from '../../../assets/sound/completed.mp3'
import ErrorSound from '../../../assets/sound/wrong-answer.mp3'
import Logout from '../../../assets/sound/logout.mp3'
import LogoutVoice from '../../../assets/sound/goodbye.mp3'
import moment from "moment";
import Helpers from "util/Helpers";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [users, setUsers] = React.useState([]);
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
 const [classData,setClassesData] = React.useState([])
 const [formValues, setFormValues] = React.useState({});
 const [checkin,isCheckIn] = useState('')
 const [loading,isLoading] = useState(false)
 const [resp ,isResp] = useState('')
 const [error ,isError] = useState('')
 const [startDate, setStartDate] = useState(Helpers.getSQLDateFromMoment(moment().startOf('day')));
 const [endDate, setEndDate] = useState(Helpers.getSQLDateFromMoment(moment().endOf('day')));
 const [totalCheckins,setTotalCheckins] = useState(0)
 
 
  React.useEffect(()=>{
 
    getClasses();
    
  },[])
  function groupAndSortByDays(data) {
    // Define the order of days
    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    // Group by days_of_the_week
    const groupedData = data.reduce((acc, item) => {
        const day = item.days_of_the_week;
        if (!acc[day]) {
            acc[day] = [];
        }
        acc[day].push(item);
        return acc;
    }, {});

    // Sort the grouped data by the order of days
    const sortedGroupedData = daysOrder.map(day => {
        return {
            day,
            sessions: groupedData[day] || [] // If there are no sessions for a day, return an empty array
        };
    });

    return sortedGroupedData;
}

      const getClasses = ()=>{
      
        fetchRecords(CLASSES,token).then((res) => {
          
         if(res.status == "Request was successful"){
         
          var data = groupAndSortByDays(res.data.allClasses)
          setClassesData(data)
          console.log(data)
            
         }
          
       }).catch(err => {
          message.error('Classes Retrieve Failed')
           console.log("error",err);
       });
      }
   
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        
        
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={classData}
              columnsData={tableColumnsTopCreators}
              refreshData={getClasses}
             
            />
          </Card>
          
        </Flex>
      </Grid>
     
      {/* Delete Product */}
    </Box>
  );
}
