import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Drawer,
   DrawerOverlay,
   DrawerContent,
    DrawerHeader, 
    DrawerBody,
    FormControl,
  FormLabel,
  InputLeftElement,InputRightElement,
  Tooltip,IconButton,NumberDecrementStepper,NumberIncrementStepper,NumberInputStepper,NumberInput,NumberInputField,
  Checkbox,NavLink,Input,InputGroup,Select,Textarea,Tag,AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from "@chakra-ui/react";
import React, { useMemo, useState,useRef } from "react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,CloseIcon
} from "@chakra-ui/icons";
import {
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {useSelector,useDispatch} from 'react-redux'
import {MdDelete, MdEdit, MdFiberSmartRecord, MdVisibility} from "react-icons/md";
import UserBanner from './UserBanner.js'
import General from './General.js'
import Plan from './Plan.js'
import Payment from './Payment.js'
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { duration } from "moment";
import moment from 'moment'
import { message } from "antd";
import {STAFF,GET_ALL_ROLES,SAVE_NEW_PLAN, GET_ALL_PERMISSION, BLOG_POST, CLASSES} from '../../../../constants/ServerUrl.js'
import { saveRecords,fetchRecords } from "appRedux/actions";
import Helpers from "util/Helpers.js";
import { merge } from "antd/es/theme/util/statistic.js";
import { DatePicker, Space } from 'antd';
import ImageModal from "./imagemodal.js";
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
const { RangePicker } = DatePicker;


function TopCreatorTable(props) {

  const { columnsData, tableData,refreshData,setStartDate,setEndDate } = props;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const[loading, isLoading] = React.useState(false)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [formValues, setFormValues] = React.useState({});
  const [duration,setDuration] = React.useState([{"duration":1},{"duration":3},{"duration":6},{"duration":12}])
  const [value, onChange] = useState('10:00');
  const [classData,setClassData] = useState([])
  const [category,setCategory] = React.useState([{"category":'Morning Session'},{"category":'Evening Session'}])
  const [daysOfTheWeek,setDaysOfTheWeek] = React.useState([{"daysOfTheWeek":'Monday'},{"daysOfTheWeek":'Tuesday'},{"daysOfTheWeek":'Wednesday'},{"daysOfTheWeek":'Thursday'},{"daysOfTheWeek":'Friday'},{"daysOfTheWeek":'Saturday'},{"daysOfTheWeek":'Sunday'}])
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [editIcon,setEditIcon] = useState(false)
  const [imageModal, setImageModal] =useState(false)
  const [imageUrl,setImageUrl] = useState('');
  
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {getTableProps, getTableBodyProps, headerGroups, page, prepareRow,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,setPageSize,state:{pageIndex,pageSize,globalFilter},setGlobalFilter,preGlobalFilteredRows}=
    tableInstance;
   

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.900", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [benefits, setBenefits] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [permissions,setPermissions] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef();
  const [showStartPicker,setShowStartPicker] = useState(false)
  const [showEndPicker,setShowEndPicker] = useState(false)
  const [searchValue, setsearchValue] = useState(globalFilter);
  const [startTime,setStartTime] = useState(null)
  const [endTime,setEndTime] = useState(null)
  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

const handleStartTimeChange =(time)=>{
  console.log(time.format('HH:mm'))
  setShowEndPicker(false)
  setFormValues({...formValues,start_time:time.format('HH:mm')})
  setStartTime(time.format('HH:mm'))
  // setShowStartPicker(false)
}
const handleShowStart = ()=>{
  setShowEndPicker(false)
  setShowStartPicker(!showStartPicker)
  
} 
const handleShowEnd = ()=>{
  setShowStartPicker(false)
  setShowEndPicker(!showEndPicker)
} 
const handleEndTimeChange =(time)=>{
  setShowStartPicker(false)
  setEndTime(time.format('HH:mm'))
  setFormValues({...formValues,end_time:time.format('HH:mm')})
 
}
 
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

 React.useEffect(()=>{
  getClasses();
 },[])

 const getClasses = ()=>{
  fetchRecords(CLASSES,token).then((res) => {
   
    if(res.status == "Request was successful"){
      
      setClassData(res.data.allClasses)
    }
    
    
 }).catch(err => {
      message.error('Fetch Permission Failedxx')
     console.log("error",err);
 });
 }

  React.useEffect(()=>{
    if(editIcon){
    
      setFormValues(selectedRowData)
   
    }else{
      setFormValues({})
    
    }
  },[editIcon])



  const closeDrawer = () => {
    
    refreshData();
    setEditIcon(false)
    setIsDrawerOpen(false);
  };
    const viewUser = (data)=>{
      setEditIcon(true)
      setSelectedRowData(data);
      setIsDrawerOpen(true);
    }

    const CreateSession = ()=>{
    
  
      const mergedObject = formValues
      const daysOfTheWeek = mergedObject.hasOwnProperty("days_of_the_week");
      const session_title = mergedObject.hasOwnProperty("session_title");
      const start_time = mergedObject.hasOwnProperty("start_time");
      const end_time = mergedObject.hasOwnProperty("end_time");
      const session_category = mergedObject.hasOwnProperty("session");
      
      if(!daysOfTheWeek){
        message.error('Days of the week is Required')
        return false
      }
      if(!session_category){
        message.error('Category is Required')
        return false
      }
      if(!session_title){
        message.error('Session name/title is Required')
        return false
      }
      if(!start_time){
        message.error('Start Time is Required')
        return false
      }
      
      if(!end_time){
        message.error('End Time is Required')
        return false
      }
      
      console.log('mergeData',mergedObject);
    
      
     
      let formData = new FormData();
       // let data = Helpers.objectFormBuilder(form, mergedObject);
      formData.append('session_title',mergedObject.session_title)
      formData.append('session',mergedObject.session)
      formData.append('days_of_the_week',mergedObject.days_of_the_week)
      formData.append('start_time',mergedObject.start_time)
      formData.append('end_time',mergedObject.end_time)
   
        let updatedURL;
        if(editIcon){
          formData.append('_method', 'put');
          updatedURL = CLASSES + "/" + selectedRowData.id
        }
        else{
          updatedURL = CLASSES
        }
      
        // return false;
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
        if (res.status =="Request was successful") {
           
            message.success(editIcon ? 'Session Successfully Updated':'Session Successfully Created')
            setFormValues({})
            setShowStartPicker(false)
            setShowEndPicker(false)
            if(editIcon){
                  closeDrawer()
            }
          
            
        } else {
            // setError(res.error);
            message.info("Error encountered");
        }
        isLoading(false);
    }).catch(err => {
        console.log(err?.response?.data);
       alert('Something Went Wrong')
        if(err?.response?.data){
            message.error(err?.response?.data)
        }
       
        isLoading(false);
    });
    }
    const OpenDrawer = ()=>{
      setEditIcon(false)
      setIsDrawerOpen(true)
    }
    const onClose = () => setIsOpen(false);
    const handleDelete=(rowData)=>{
      setSelectedRowData(rowData)
     setIsOpen(true)
    }

    const _delete = ()=>{
      let formData =  new FormData()
      formData.append('_method', 'delete');
      let updatedURL = GET_ALL_PERMISSION + "/" + selectedRowData.id
     
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
          if (res.status =="Request was successful") {
            
              message.success('Plan Successfully Deleted')
              refreshData()
              setIsOpen(false)
              
          } else {
              // setError(res.error);
              message.info("Error encountered while deleting plan");
          }
          isLoading(false);
        }).catch(err => {
          console.log(err?.response?.data);
          if(err?.response?.data){
              message.error(err?.response?.data)
          }
        
          isLoading(false);
      });
    }

   
      // const handleDateRangeChange = (dates) => {
      //   console.log(dates)
      //   setDateRange(dates);
        
      // };
      const handleDateRangeChange = (dates) => {
      
        if (dates) {
          setStartDate(Helpers.getSQLDateFromMoment(dates[0].startOf('day')));
          setEndDate(Helpers.getSQLDateFromMoment(dates[1].endOf('day')));
        } else {
          setStartDate(null);
          setEndDate(null);
        }
      };

      const handleImageModal = (imageUrl)=>{
        setImageUrl(imageUrl)
        setImageModal(true)

      }

      const bgColor = useColorModeValue('blue.50', 'blue.900'); // Background color based on theme
      //const textColor = useColorModeValue('blue.700', 'blue.200'); // Text color based on theme
      const borderColor = useColorModeValue('blue.300', 'blue.600'); // Border color based on theme
    
    

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Class/Session Table
          </Text>
          <Button variant='action' onClick={()=>OpenDrawer()}>Create Class/Session</Button>
          {/* <Space direction="horizontal" size={12}>
            <Text>Filter Date</Text>
            <RangePicker
              onChange={handleDateRangeChange}
              ranges={{
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
            }}
        
            />
          </Space> */}
        </Flex>
        <Box mb={"20px"} ml={'10px'} width={'sm'} alignSelf={"flex-end"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              ref={inputRef}
              type="text"
              value={searchValue || ""}
              onChange={(e) => {
                setsearchValue(inputRef.current.value);
                onSearchInputChange(inputRef.current.value);
              }}
              placeholder={`Search Session`}
            />
            {searchValue && (
              <InputRightElement
                cursor={"pointer"}
                children={
                  <CloseIcon
                    fontSize={14}
                    _hover={{ color: "gray.600" }}
                    color="gray.300"
                  />
                }
                onClick={() => {
                  setGlobalFilter("");
                  setsearchValue("");
                }}
              />
            )}
          </InputGroup>
        </Box>

        {imageModal && <ImageModal isOpen={imageModal} onClose={setImageModal} imageUrl={imageUrl}/>}
       
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='secondaryGray.900'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
             
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    
                    let data = "";
                   
                    if (cell.column.Header === "Morning Session") {
                     
                      row.original.sessions.length > 0 && row.original.sessions.map((d,indexD)=>{
                      
                        if(d.session == "Morning Session"){
                            data = (
                              <Box
                                key={indexD}
                                bg={bgColor}
                                borderRadius='md'
                                border='1px solid'
                                borderColor={borderColor}
                                p={4}
                                boxShadow='lg'
                                maxW='sm'
                                mx='auto'
                                mt={4}
                              >
                                <Flex align='center' flexDirection={'column'} cursor={'pointer'} onClick={()=>viewUser(d)}>
                                 <Text color={textColor} fontSize='lg' fontWeight='700' mb={'2'}>
                                    {row.original.day} - {d.session}
                                  </Text>
                                  <Text color={textColor} fontSize='lg' fontWeight='700'  mb={'2'}>
                                    {d.session_title}
                                  </Text>
                                  <Text color={textColor} fontSize='lg' fontWeight='700'>
                                    {d.start_time} - {d.end_time}
                                  </Text>
                                </Flex>
                              </Box>
                          );
                        }
                      })
                      
                    } 

                    else if (cell.column.Header === "SN") {
                      data = (
                        <Tag size="md" display="flex" justifyContent="flex-start" alignItems="center">
                          {rowIndex + 1}
                        </Tag>
                      );
                     
                    }

                    else if (cell.column.Header === "Eveninig Session") {
                      
                      row.original.sessions.length > 0 && row.original.sessions.map((d,indexD)=>{
                      
                        if(d.session == "Evening Session"){
                            data = (
                              <Box
                                key={indexD}
                                bg={bgColor}
                                borderRadius='md'
                                border='1px solid'
                                borderColor={borderColor}
                                p={4}
                                boxShadow='lg'
                                maxW='sm'
                                mx='auto'
                                mt={4}
                              >
                                <Flex align='center' flexDirection={'column'} cursor={'pointer'} onClick={()=>viewUser(d)}>
                                  <Text color={textColor} fontSize='lg' fontWeight='700' mb={'2'}>
                                      {row.original.day} - {d.session}
                                    </Text>
                                  <Text color={textColor} fontSize='lg' fontWeight='700' mb={'2'}>
                                    {d.session_title}
                                  </Text>
                                  <Text color={textColor} fontSize='lg' fontWeight='700'>
                                    {d.start_time} - {d.end_time}
                                  </Text>
                                </Flex>
                              </Box>
                          );
                        }
                      })
                      
                    } 
                 
                    else if (cell.column.Header === "Action") {
                      data = (
                        <Box style={{display:"flex",flexDirection:"row"}} >
                          {/* <MdEdit style={{marginRight:5,cursor:'pointer'}} onClick={()=>viewUser(row.original)}/> */}
                          {/* <MdDelete style={{cursor:'pointer'}} onClick={()=>handleDelete(row.original)}/> */}
                        </Box>
                      );
                    }
                  
                    else  {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>

        <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>{editIcon ? 'Edit Session':'Create Session'}</DrawerHeader>
            <DrawerBody>

            <FormControl>

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Week Days<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.days_of_the_week || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Week Day"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,days_of_the_week:event.target.value})}
        >
            {daysOfTheWeek && daysOfTheWeek.length > 0 && daysOfTheWeek.map((d,index)=>{
              return <option value={d.daysOfTheWeek}>{d.daysOfTheWeek}</option>
            })}
        </Select>

        <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Session category<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.session || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Category"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,session:event.target.value})}
        >
            {category && category.length > 0 && category.map((d,index)=>{
              return <option value={d.category}>{d.category}</option>
            })}
        </Select>
            
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Session Title<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              value={formValues.session_title || ''}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Session Title'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,session_title:event.target.value})}
            />

            <div style={{display:'flex'}}>
              <div>
                <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Start Time<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                value={formValues.start_time || ''}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='Start Time'
                mb='24px'
                readOnly
                fontWeight='500'
                size='lg'
                onClick={()=>handleShowStart()}
                
              />
              {
                showStartPicker && <Datetime 
                dateFormat={false} 
                timeFormat="HH:mm A" 
                onChange={handleStartTimeChange} 
                value={startTime ? moment(startTime,'HH:mm'):moment()}  
                
                input={false}               // Hide the default input
                open={true} 
                />
              }
              </div>

              <div style={{marginLeft:10}}>
                <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                End Time<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                value={formValues.end_time || ''}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='End Time'
                mb='24px'
                fontWeight='500'
                size='lg'
                readOnly
                onClick={()=>handleShowEnd()}
              />
              {
                showEndPicker && <Datetime 
                dateFormat={false} 
                timeFormat="HH:mm A" 
                onChange={handleEndTimeChange} 
                value={endTime ? moment(endTime,'HH:mm'):moment()}  
                
                input={false}               // Hide the default input
                open={true} 
                />
              }
              </div>
            
            </div>
         
          
           
       
            <Button
              style={{marginTop:30}}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={CreateSession}>
              {editIcon ?'Update Session':'Create Session'}
            </Button>
          </FormControl>

          
            

           
            
           
           
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose} >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation</AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to proceed?</AlertDialogBody>
            <AlertDialogFooter>
              <Button  onClick={()=>onClose()}>Cancel</Button>
              <Button isLoading={loading} colorScheme="red" onClick={()=>_delete()}  ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
