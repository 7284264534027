// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import moment from "moment";
// Assets
export default function GeneralInformation(props) {
  const {refresh, ...rest } = props;
  // Chakra Color Mode
  console.log('rest',rest)
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const RetrivedDataComponent = ()=>{
    if(rest.data && rest.data.length > 0){
      return rest.data.map((d,index)=>{
        return <SimpleGrid columns='1' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Plan Type'
          value={d.plan_selected}
        />
        <Information
          boxShadow={cardShadow}
          title='Plan Amount'
          value={d.plan_amount}
        />
        <Information
          boxShadow={cardShadow}
          title='Coach'
          value={d.coach}
        />
        
        {
          d.coach == "YES" && <Information
          boxShadow={cardShadow}
          title='Coach Amount'
          value={d.coach_amount}
        />
        }

        <Information
          boxShadow={cardShadow}
          title='Coach Expiry'
          value={moment(d.coach_expiry_date).format('DD-MM-YYYY')}
          edit="Edit Coach Expiry Date"
          rowId={d.id}
          refresh={refresh}
        />
       
        <Information
          boxShadow={cardShadow}
          title='Plan Expiry'
          value={moment(d.expiry_date).format('DD-MM-YYYY')}
          edit="Edit Expiry Date"
          rowId={d.id}
          refresh={refresh}
        />
       
      </SimpleGrid>
      })
    }
    else{
      return   <Information
      boxShadow={cardShadow}
      title='No DATA'
      value='No Data Found'
    />
    }
  }
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Plan Information
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
      {rest.loading ? <Information
          boxShadow={cardShadow}
          title='Retrieving Data'
          value='Please wait'
        />:RetrivedDataComponent()}
      
    
    </Card>
  );
}
