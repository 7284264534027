export const tableColumnsTopCreators = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Blog Title",
    accessor: "title",
  },
  {
    Header: "Blog Category",
    accessor: "category",
  },
  {
    Header: "Blog Type",
    accessor: "type",
  },
 
  {
    Header: "Action",
    accessor: "actions",
  },
];
