export const tableColumnsTopCreators = [
  {
    Header:'SN',
    accessor:''
  },
  {
    Header: "Payment Type",
    accessor: "payment_type",
  },
  {
    Header: "User's Email",
    accessor: "email",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Transaction Ref",
    accessor: "transRef",
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
  },
  {
    Header: "userid",
    accessor: "userid",
  },
  {
    Header: "created_by",
    accessor: "created_by",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
