import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import {store,persistor} from 'appRedux/store'
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import AuthRoute from './AuthRoute';
//const {userdetails} = useSelector((state)=>state.UserDetails)
const checkUserLoggedIn = ()=>{
	var loginToken = localStorage.getItem('token') 
	if(!loginToken){
		return false
	}
	else{
		return true
	}
	
}
// auth.js


  
ReactDOM.render(
	
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeEditorProvider>
							<HashRouter>
							<Switch>
								{/* <Route path={`/auth`} component={AuthLayout} /> */}
								<AuthRoute path="/auth" component={AuthLayout} />
								<ProtectedRoute path="/admin" component={AdminLayout} />
								
								{/* <Route
									path="/auth"
									render={() => {
										// Check if the user is logged in, replace this with your actual authentication logic
										const isLoggedIn = checkUserLoggedIn(); // Replace this with your actual authentication check

										if (isLoggedIn) {
										return <AdminLayout />;
										} else {
										return <AuthLayout/>;
										}
									}}
								/> */}

								{/* <Route path={`/admin`} component={AdminLayout} /> */}
								<Route path={`/rtl`} component={RtlLayout} />
								<Redirect from='/' to='/auth' />
							</Switch>
						</HashRouter>
					</ThemeEditorProvider>
				</PersistGate>
			</Provider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
