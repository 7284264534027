import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Drawer,
   DrawerOverlay,
   DrawerContent,
    DrawerHeader, 
    DrawerBody,
    FormControl,
  FormLabel,
  InputLeftElement,InputRightElement,
  Tooltip,IconButton,NumberDecrementStepper,NumberIncrementStepper,NumberInputStepper,NumberInput,NumberInputField,
  Checkbox,NavLink,Input,InputGroup,Select,Textarea,Tag,AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from "@chakra-ui/react";
import React, { useMemo, useState,useRef } from "react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,CloseIcon
} from "@chakra-ui/icons";
import {
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {useSelector,useDispatch} from 'react-redux'
import {MdDelete, MdEdit, MdFiberSmartRecord, MdOutlineAssignment, MdVisibility} from "react-icons/md";
import UserBanner from './UserBanner.js'
import General from './General.js'
import Plan from './Plan.js'
import Payment from './Payment'
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { duration } from "moment";
import { message } from "antd";
import {STAFF,GET_ALL_ROLES,SAVE_NEW_PLAN, GET_ALL_TRAINER, SCHEDULES} from '../../../../constants/ServerUrl'
import { saveRecords,fetchRecords } from "appRedux/actions";
import Helpers from "util/Helpers.js";



function TopCreatorTable(props) {
  const { columnsData, tableData,refreshData} = props;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const[loading, isLoading] = React.useState(false)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [formValues, setFormValues] = React.useState({});
  const [duration,setDuration] = React.useState([{"duration":1},{"duration":3},{"duration":6},{"duration":12}])
  const [planStatus,setPlanStatus] = React.useState([{"planstatus":'active'},{"planstatus":'inactive'}])
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [editIcon,setEditIcon] = useState(false)

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {getTableProps, getTableBodyProps, headerGroups, page, prepareRow,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,setPageSize,state:{pageIndex,pageSize,globalFilter},setGlobalFilter,preGlobalFilteredRows}=
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.900", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [benefits, setBenefits] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [roles,setRoles] = useState([])
  const inputRef = useRef();
  const [searchValue, setsearchValue] = useState(globalFilter);
  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);


 React.useEffect(()=>{
  getTrainer();
 },[])

 const getTrainer = ()=>{
  fetchRecords(GET_ALL_TRAINER,token).then((res) => {
    console.log('result',res.data.all_trainers)
    setRoles(res.data.all_trainers)
    
 }).catch(err => {
      message.error('Fetch Trainer Failed')
     console.log("error",err);
 });
 }

  React.useEffect(()=>{
    if(editIcon){
      console.log('editIconxxx',selectedRowData.plan_benefit)
      let convertedJson = parseJsonToArray(selectedRowData.plan_benefit)
     console.log('convertedjson',convertedJson);
      setFormValues(selectedRowData)
      setBenefits(convertedJson)
    }else{
      setFormValues({})
      setBenefits([])
    }
  },[editIcon])

  // function parseJsonToArray(jsonString) {
  //   try {
  //     // Attempt to parse the JSON string directly
  //     return JSON.parse(jsonString);
  //   } catch (error) {
  //     // If parsing fails, remove any backslashes and then parse the modified string
  //     const modifiedString = jsonString.replace(/\\/g, '');
  //     return JSON.parse(JSON.parse(modifiedString));
  //   }
  // }
  function parseJsonToArray(jsonString) {
    try {
      const parsedJson = JSON.parse(jsonString);
      
      if (Array.isArray(parsedJson)) {
        return parsedJson;
      } else {
        return [parsedJson];
      }
    } catch (error) {
      return [];
    }
  }
 
  


  const handleAddBenefit = () => {
    setBenefits([...benefits, { [`benefit${benefits.length + 1}`]: '' }]);
    console.log('benefit',benefits)
  };
  const handleBenefitChange = (index, value) => {
    if (value !== "") {
      setBenefits((prevBenefits) => {
        const updatedBenefits = [...prevBenefits];
        updatedBenefits[index] = { [`benefit${index + 1}`]: value };
        return updatedBenefits;
      });
    } else {
      setBenefits((prevBenefits) => {
        const updatedBenefits = [...prevBenefits];
        updatedBenefits.splice(index, 1);
        return updatedBenefits;
      });
    }
  };

  // const handleBenefitChange = (index, value) => {
  //   console.log("value",value)
  //   if(value != ""){
  //     console.log(value)
  //     setBenefits((prevBenefits) => {
  //       if(value !== ""){
  //         const updatedBenefits = [...prevBenefits];
  //         updatedBenefits[index] = { [`benefit${index + 1}`]: value };
  //         return updatedBenefits;
  //       }
        
  //     });
  //   }
   
  // };
  const closeDrawer = () => {
    
    refreshData();
    setEditIcon(false)
    setIsDrawerOpen(false);
  };
    const viewUser = (data)=>{
      setEditIcon(true)
      setSelectedRowData(data);
      // setIsDrawerOpen(true);
    }
const renderBenefit=()=>{
  return benefits.map((benefit,index) => {
    if (typeof benefit === 'string') {
     
      const parsedItem = JSON.parse(benefit);
      // return parsedItem[0].benefit1;
      console.log('here',parsedItem)
      return parsedItem.map((benefitUpdate,index)=>{
        return <Input
        key={index}
        value={benefitUpdate[`benefit${index + 1}`]}
        isRequired={true}
        variant='auth'
        fontSize='sm'
        ms={{ base: "0px", md: "0px" }}
        placeholder='Enter Benefit'
        mb='24px'
        fontWeight='500'
        size='lg'
        onChange={(event) => handleBenefitChange(index, event.target.value)}
      />
      })
     
    }
    else{
      return <Input
      key={index}
      value={benefit[`benefit${index + 1}`]}
      isRequired={true}
      variant='auth'
      fontSize='sm'
      ms={{ base: "0px", md: "0px" }}
      placeholder='Enter Benefit'
      mb='24px'
      fontWeight='500'
      size='lg'
      onChange={(event) => handleBenefitChange(index, event.target.value)}
    />
    }

   
  });
}
    const assignTrainer = ()=>{
    
  
      const mergedObject = formValues
      
   
    const hasRole = mergedObject.hasOwnProperty("trainer");
      if(!hasRole){
        message.error('Select A Trainer')
        return false
      }
      
      let formData = new FormData();
       // let data = Helpers.objectFormBuilder(form, mergedObject);
      formData.append('trainer_id',mergedObject.trainer)
      formData.append('_method',"PUT")
      saveRecords(SCHEDULES+'/'+selectedRowData.id, formData,token).then(res => {
        
        isLoading(false)
        if (res.status =="Request was successful") {
           
            message.success('Trainer has been assigned')
            setFormValues({})
            closeDrawer()
            
        } else {
            // setError(res.error);
            message.info("Error encountered while assigning trainer");
        }
        isLoading(false);
    }).catch(err => {
        console.log(err?.response?.data);
        if(err?.response?.data){
            message.error(err?.response?.data)
        }
       
        isLoading(false);
    });
    }
    const OpenDrawer = ()=>{
      setEditIcon(false)
      setIsDrawerOpen(true)
    }
    const onClose = () => setIsOpen(false);
    const handleDelete=(rowData)=>{
      setSelectedRowData(rowData)
     setIsOpen(true)
    }

    const _delete = ()=>{
      let formData =  new FormData()
      formData.append('_method', 'delete');
      let updatedURL = STAFF + "/" + selectedRowData.id
     
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
          if (res.status =="Request was successful") {
            
              message.success('Plan Successfully Deleted')
              refreshData()
              setIsOpen(false)
              
          } else {
              // setError(res.error);
              message.info("Error encountered while deleting plan");
          }
          isLoading(false);
        }).catch(err => {
          console.log(err?.response?.data);
          if(err?.response?.data){
              message.error(err?.response?.data)
          }
        
          isLoading(false);
      });
    }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            All Schedules
          </Text>
          {/* <Button variant='action' onClick={()=>OpenDrawer()}>Create New Staff</Button> */}
        </Flex>
        <Box mb={"20px"} ml={'10px'} width={'sm'} alignSelf={"flex-end"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              ref={inputRef}
              type="text"
              value={searchValue || ""}
              onChange={(e) => {
                setsearchValue(inputRef.current.value);
                onSearchInputChange(inputRef.current.value);
              }}
              placeholder={`Search Schedules`}
            />
            {searchValue && (
              <InputRightElement
                cursor={"pointer"}
                children={
                  <CloseIcon
                    fontSize={14}
                    _hover={{ color: "gray.600" }}
                    color="gray.300"
                  />
                }
                onClick={() => {
                  setGlobalFilter("");
                  setsearchValue("");
                }}
              />
            )}
          </InputGroup>
        </Box>
       
       
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='secondaryGray.900'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                   
                    if (cell.column.Header === "First Name") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } 
                    else if (cell.column.Header === "SN") {
                      data = (
                        <Tag size="md" display="flex" justifyContent="center" alignItems="center">
                          {rowIndex + 1}
                        </Tag>
                      );
                     
                    }
                    
                  else if (cell.column.Header === "Status") {
                      data = (
                        <Box>
                          
                          <Tag size="md"  variant="outline" colorScheme={cell.value == "active"? 'green':'gray'}>{cell.value}</Tag>
                        </Box>
                      );
                    }
                    else if (cell.column.Header == "#") {
                      data = (
                        <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='500'>
                        #
                      </Text>
                      );
                    }
                    else if (cell.column.Header == "Instructor") {
                      data = (
                        <Box>
                          {
                            row.original.instructor_id == "" ? <Tag size="md"  variant="outline" colorScheme='yellow'>unassigned</Tag>: <Tag size="md"  variant="outline" colorScheme='green'>{row.original.instructor_firstname } {row.original.instructor_lastname }</Tag>
                          }
                          
                        {/* <Tag size="md"  variant="outline" colorScheme='green'>{row.original.roles[0].name}</Tag> */}
                      </Box>
                      );
                    }
                    else if (cell.column.Header === "Action") {
                      data = (
                        <Box style={{display:"flex",flexDirection:"row"}} >
                          <MdOutlineAssignment style={{marginRight:5,cursor:'pointer'}} onClick={()=>viewUser(row.original)}/>
                          {/* <MdEdit /> */}
                          {/* <MdDelete style={{cursor:'pointer'}} onClick={()=>handleDelete(row.original)}/> */}
                        </Box>
                      );
                    }
                  
                    else  {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>

        <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Assign Trainer</DrawerHeader>
            <DrawerBody>

            <FormControl>
            
           
            
                  <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  List Of Trainers<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  placeholder="Select an Trainer"
                  mb="24px"
                  value={formValues.trainer || ''}
                  fontWeight="500"
                  size="lg"
                  onChange={(event)=>setFormValues({...formValues,trainer:event.target.value})}
                >
              {roles && roles.length > 0 && roles.map((d,index)=>{
                return <option value={d.id}>{d.firstname}  {d.lastname}</option>
              })}
            </Select>
            
          
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={assignTrainer}>
              Assign Trainer
            </Button>
          </FormControl>
            

           
            
           
           
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose} >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation</AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to proceed?</AlertDialogBody>
            <AlertDialogFooter>
              <Button  onClick={()=>onClose()}>Cancel</Button>
              <Button isLoading={loading} colorScheme="red" onClick={()=>_delete()}  ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
