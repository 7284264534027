export const tableColumnsTopCreators = [
  {
    Header:'SN',
    accessor:''
  },
  {
    Header: "Expense Description",
    accessor: "expense_description",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Created By",
    accessor: (row) => `${row.created_by_firstname} ${row.created_by_lastname}`,
  },
  {
    Header: "Approved By",
    accessor: (row) => `${row.approved_by_firstname} ${row.approved_by_lastname}`,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
