import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Drawer,
   DrawerOverlay,
   DrawerContent,
    DrawerHeader, 
    DrawerBody,
    FormControl,
  FormLabel,
  InputLeftElement,InputRightElement,
  Tooltip,IconButton,NumberDecrementStepper,NumberIncrementStepper,NumberInputStepper,NumberInput,NumberInputField,
  Checkbox,NavLink,Input,InputGroup,Select,Textarea,Tag,AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from "@chakra-ui/react";
import React, { useMemo, useState,useRef } from "react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,CloseIcon
} from "@chakra-ui/icons";
import {
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {useSelector,useDispatch} from 'react-redux'
import {MdDelete, MdEdit, MdFiberSmartRecord, MdVisibility} from "react-icons/md";
import UserBanner from './UserBanner.js'
import General from './General.js'
import Plan from './Plan.js'
import Payment from './Payment'
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { duration } from "moment";
import moment from 'moment'
import { message } from "antd";
import {STAFF,GET_ALL_ROLES,SAVE_NEW_PLAN, GET_ALL_PERMISSION, BLOG_POST} from '../../../../constants/ServerUrl'
import { saveRecords,fetchRecords } from "appRedux/actions";
import Helpers from "util/Helpers.js";
import { merge } from "antd/es/theme/util/statistic.js";
import { DatePicker, Space } from 'antd';
import ImageModal from "./imagemodal.js";
const { RangePicker } = DatePicker;


function TopCreatorTable(props) {

  const { columnsData, tableData,refreshData,setStartDate,setEndDate,tableCount } = props;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const[loading, isLoading] = React.useState(false)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [formValues, setFormValues] = React.useState({});
  const [duration,setDuration] = React.useState([{"duration":1},{"duration":3},{"duration":6},{"duration":12}])
  const [blogType,setBlogType] = React.useState([{"blogtype":'text'},{"blogtype":'video'}])
  const [category,setCategory] = React.useState([{"category":'arm'},{"category":'chest'},{"category":'fitness'}])
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [editIcon,setEditIcon] = useState(false)
  const [imageModal, setImageModal] =useState(false)
  const [imageUrl,setImageUrl] = useState('');
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {getTableProps, getTableBodyProps, headerGroups, page, prepareRow,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,setPageSize,state:{pageIndex,pageSize,globalFilter},setGlobalFilter,preGlobalFilteredRows}=
    tableInstance;
   

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.900", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [benefits, setBenefits] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [permissions,setPermissions] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef();
  const [searchValue, setsearchValue] = useState(globalFilter);
  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);


 
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

 React.useEffect(()=>{
  getPermissions();
 },[])

 const getPermissions= ()=>{
  fetchRecords(GET_ALL_PERMISSION).then((res) => {
    console.log('result',res.data.permission)
    setPermissions(res.data.permission)
    
 }).catch(err => {
      message.error('Fetch Permission Failed')
     console.log("error",err);
 });
 }

  React.useEffect(()=>{
    if(editIcon){
    
      setFormValues(selectedRowData)
   
    }else{
      setFormValues({})
    
    }
  },[editIcon])



  const closeDrawer = () => {
    
    refreshData();
    setEditIcon(false)
    setIsDrawerOpen(false);
  };
    const viewUser = (data)=>{
      setEditIcon(true)
      setSelectedRowData(data);
      setIsDrawerOpen(true);
    }

    const CreateBlog = ()=>{
    
  
      const mergedObject = formValues
      
      const blog_title = mergedObject.hasOwnProperty("title");
      const blog_type = mergedObject.hasOwnProperty("type");
      const blog_category = mergedObject.hasOwnProperty("category");
      
      if(!blog_title){
        message.error('Blog Title is Required')
        return false
      }
      if(!blog_category){
        message.error('Blog Category is Required')
        return false
      }
      if(!blog_type){
        message.error('Blog Type is Required')
        return false
      }
      
      const blogfile = mergedObject.hasOwnProperty("blogfile");
      const blogbody = mergedObject.hasOwnProperty("blogbody");
     
      if(!selectedFile && !blogbody ){
        message.error('Blog Text/Blog video is Required')
        return false
      }
      
     
      let formData = new FormData();
       // let data = Helpers.objectFormBuilder(form, mergedObject);
      formData.append('title',mergedObject.title)
      formData.append('category',mergedObject.category)
      formData.append('blogfile',selectedFile)
      formData.append('blogbody',mergedObject.blogbody)
        let updatedURL;
        if(editIcon){
          formData.append('_method', 'put');
          updatedURL = BLOG_POST + "/" + selectedRowData.id
        }
        else{
          updatedURL = BLOG_POST
        }
       console.log(mergedObject)
        // return false;
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
        if (res.status =="Request was successful") {
           
            message.success(editIcon ? 'Blog Post Successfully Updates':'Blog Post Successfully Created')
            setFormValues({})
           
            closeDrawer()
            
        } else {
            // setError(res.error);
            message.info("Error encountered while creating blog post");
        }
        isLoading(false);
    }).catch(err => {
        console.log(err?.response?.data);
        if(err?.response?.data){
            message.error(err?.response?.data)
        }
       
        isLoading(false);
    });
    }
    const OpenDrawer = ()=>{
      setEditIcon(false)
      setIsDrawerOpen(true)
    }
    const onClose = () => setIsOpen(false);
    const handleDelete=(rowData)=>{
      setSelectedRowData(rowData)
     setIsOpen(true)
    }

    const _delete = ()=>{
      let formData =  new FormData()
      formData.append('_method', 'delete');
      let updatedURL = GET_ALL_PERMISSION + "/" + selectedRowData.id
     
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
          if (res.status =="Request was successful") {
            
              message.success('Plan Successfully Deleted')
              refreshData()
              setIsOpen(false)
              
          } else {
              // setError(res.error);
              message.info("Error encountered while deleting plan");
          }
          isLoading(false);
        }).catch(err => {
          console.log(err?.response?.data);
          if(err?.response?.data){
              message.error(err?.response?.data)
          }
        
          isLoading(false);
      });
    }

   
      // const handleDateRangeChange = (dates) => {
      //   console.log(dates)
      //   setDateRange(dates);
        
      // };
      const handleDateRangeChange = (dates) => {
      
        if (dates) {
          setStartDate(Helpers.getSQLDateFromMoment(dates[0].startOf('day')));
          setEndDate(Helpers.getSQLDateFromMoment(dates[1].endOf('day')));
        } else {
          setStartDate(null);
          setEndDate(null);
        }
      };

      const handleImageModal = (imageUrl)=>{
        setImageUrl(imageUrl)
        setImageModal(true)

      }
    

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            All CheckIn Users ({tableCount})
          </Text>
          {/* <Button variant='action' onClick={()=>OpenDrawer()}>Create New BlogPost</Button> */}
          <Space direction="horizontal" size={12}>
            <Text>Filter Date</Text>
            <RangePicker
              onChange={handleDateRangeChange}
              ranges={{
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
            }}
        
            />
          </Space>
        </Flex>
        <Box mb={"20px"} ml={'10px'} width={'sm'} alignSelf={"flex-end"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              ref={inputRef}
              type="text"
              value={searchValue || ""}
              onChange={(e) => {
                setsearchValue(inputRef.current.value);
                onSearchInputChange(inputRef.current.value);
              }}
              placeholder={`Search Users`}
            />
            {searchValue && (
              <InputRightElement
                cursor={"pointer"}
                children={
                  <CloseIcon
                    fontSize={14}
                    _hover={{ color: "gray.600" }}
                    color="gray.300"
                  />
                }
                onClick={() => {
                  setGlobalFilter("");
                  setsearchValue("");
                }}
              />
            )}
          </InputGroup>
        </Box>

        {imageModal && <ImageModal isOpen={imageModal} onClose={setImageModal} imageUrl={imageUrl}/>}
       
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='secondaryGray.900'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                   
                    if (cell.column.Header === "Full name") {
                      
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src={row.original?.profile_uri ? row.original?.profile_uri:'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80'}
                            w='30px'
                            h='30px'
                            me='8px'
                            onClick={()=>handleImageModal(row.original?.profile_uri ? row.original?.profile_uri:'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80')}
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } 

                    else if (cell.column.Header === "SN") {
                      data = (
                        <Tag size="md" display="flex" justifyContent="center" alignItems="center">
                          {rowIndex + 1}
                        </Tag>
                      );
                     
                    }
                 
                    else if (cell.column.Header == "#") {
                      data = (
                        <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='500'>
                        {cell.value}
                      </Text>
                      );
                    }
                   
                    else if (cell.column.Header === "Action") {
                      data = (
                        <Box style={{display:"flex",flexDirection:"row"}} >
                          {/* <MdEdit style={{marginRight:5,cursor:'pointer'}} onClick={()=>viewUser(row.original)}/> */}
                          {/* <MdDelete style={{cursor:'pointer'}} onClick={()=>handleDelete(row.original)}/> */}
                        </Box>
                      );
                    }
                  
                    else  {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>

        <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>{editIcon ? 'Edit New BlogPost':'Create New BlogPost'}</DrawerHeader>
            <DrawerBody>

            <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog Title<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              value={formValues.title || ''}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Blog Title'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,title:event.target.value})}
            />
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog category<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.category || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Category"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,category:event.target.value})}
        >
            {category && category.length > 0 && category.map((d,index)=>{
              return <option value={d.category}>{d.category}</option>
            })}
        </Select>
            
       
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog type<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.type || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Blog Type"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,type:event.target.value})}
        >
            {blogType && blogType.length > 0 && blogType.map((d,index)=>{
              return <option value={d.blogtype}>{d.blogtype}</option>
            })}
        </Select>
      
        {
          formValues.type == "text" &&
          <Textarea  value={formValues.blogbody || ''} onChange={(event)=>setFormValues({...formValues,blogbody:event.target.value})} placeholder="Write your blog post"></Textarea>
        }
        {
          formValues.type == "video" &&
          <>
            <label>Select the video file</label><Input type="file" onChange={handleFileChange} />
          </>
        }
          
        
            <Button
              style={{marginTop:30}}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={CreateBlog}>
              {editIcon ?'Update Blog Post':'Create Blog Post'}
            </Button>
          </FormControl>
            

           
            
           
           
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose} >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation</AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to proceed?</AlertDialogBody>
            <AlertDialogFooter>
              <Button  onClick={()=>onClose()}>Cancel</Button>
              <Button isLoading={loading} colorScheme="red" onClick={()=>_delete()}  ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
