/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
   DrawerHeader, 
   DrawerBody
} from "@chakra-ui/react";

// Custom components

import TableTopCreators from "views/admin/roles/components/TableTopCreators";
import HistoryItem from "views/admin/roles/components/HistoryItem";

import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/roles/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/roles/variables/tableColumnsTopCreators";
import {message} from 'antd'
import { saveRecords,fetchRecords } from "appRedux/actions";
import {useSelector,useDispatch} from 'react-redux'
import {GET_ALL_ROLES, STAFF} from '../../../constants/ServerUrl'

export default function Roles() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [users, setUsers] = React.useState([]);
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
 const [roles,setallroles] = React.useState([])
 
 
  React.useEffect(()=>{
    getAllRoles();
    
  },[])
      const getAllRoles = ()=>{
        console.log('fetched')
        fetchRecords(GET_ALL_ROLES,token).then((res) => {
          
          message.info('Roles Retrieved')
          
          setallroles(res.data.roles)
          
       }).catch(err => {
          message.error('Roles Retrieve Failed')
           console.log("error",err);
       });
      }
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        
        
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={roles}
              columnsData={tableColumnsTopCreators}
              refreshData={getAllRoles}
            />
          </Card>
          <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Active Subscriptions
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>

            <HistoryItem
              name='Temiloluwa Odewumi'
              author='By Mark Benjamin'
              date=''
              image={Nft5}
              price='N20,000'
            />
            <HistoryItem
              name='Geoffrey Odewumi'
              author='By Esthera Jackson'
              date=''
              image={Nft1}
              price='N29,000'
            />
          
           
          
           
          </Card>
         
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
