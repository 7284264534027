export const tableColumnsTopCreators = [
  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "Morning Session",
    accessor: "session_title",
  },
  {
    Header: "Eveninig Session",
    accessor: "",
  },
  
  

];
