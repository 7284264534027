export const tableColumnsTopCreators = [
  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "PayDay",
    accessor: "",
  },
  {
    Header: "Last Modified",
    accessor: "",
  },
  

  {
    Header: "Action",
    accessor: "actions",
  },
];
