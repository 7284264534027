export const tableColumnsTopCreators = [

  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "First Name",
    accessor: "firstname",
  },
  {
    Header: "Last name",
    accessor: "lastname",
  },
  {
    Header: "User id",
    accessor: "user_id",
  },
  {
    Header: "Expiry Date",
    accessor: "",
  },
 
  {
    Header: "Instructor",
    accessor: "",
  },
  
  {
    Header: "Status",
    accessor: "assign",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
