export const tableColumnsTopCreators = [
  {
    Header:'SN',
    accessor:''
  },
  {
    Header: "UserId",
    accessor: "userId",
  },
  {
    Header: "First Name",
    accessor: "firstname",
  },
  {
    Header: "Last name",
    accessor: "lastname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Expiry Date",
    accessor: "expiry_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
