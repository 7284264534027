import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Drawer,
   DrawerOverlay,
   DrawerContent,
    DrawerHeader, 
    DrawerBody,
    FormControl,
  FormLabel,
  Checkbox,NavLink,Input,InputGroup,Select,Textarea,Tag,AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {useSelector,useDispatch} from 'react-redux'
import {MdDelete, MdEdit, MdFiberSmartRecord, MdVisibility} from "react-icons/md";
import UserBanner from './UserBanner.js'
import General from './General.js'
import Plan from './Plan.js'
import Payment from './Payment'
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { duration } from "moment";
import { message } from "antd";
import {STAFF,GET_ALL_ROLES,SAVE_NEW_PLAN, GET_ALL_PERMISSION, BLOG_POST} from '../../../../constants/ServerUrl'
import { saveRecords,fetchRecords } from "appRedux/actions";
import Helpers from "util/Helpers.js";
import { merge } from "antd/es/theme/util/statistic.js";
import 'braft-editor/dist/index.css';
import BraftEditor from "braft-editor";


function TopCreatorTable(props) {
  const { columnsData, tableData,refreshData } = props;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const[loading, isLoading] = React.useState(false)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [formValues, setFormValues] = React.useState({});
  const [duration,setDuration] = React.useState([{"duration":1},{"duration":3},{"duration":6},{"duration":12}])
  const [blogType,setBlogType] = React.useState([{"blogtype":'text'},{"blogtype":'video'}])
  const [category,setCategory] = React.useState([{"category":'arm'},{"category":'chest'},{"category":'abs'},{"category":'event'}])
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [editIcon,setEditIcon] = useState(false)
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''));
  const [htmlContent, setHtmlContent] = useState('<p></p>');
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
   

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.900", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [benefits, setBenefits] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [permissions,setPermissions] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [result,isResult] = useState('')
  const [publicId,isPublicid] = useState('')


  const handleFileChange = (event) => {
    console.log('herere',event.target.files[0])
    setSelectedFile(event.target.files[0]);
  };

 React.useEffect(()=>{
  getPermissions();
 },[])

 const getPermissions= ()=>{
  fetchRecords(GET_ALL_PERMISSION).then((res) => {
    console.log('result',res.data.permission)
    setPermissions(res.data.permission)
    
 }).catch(err => {
      message.error('Fetch Permission Failed')
     console.log("error",err);
 });
 }

  React.useEffect(()=>{
    if(editIcon){
    
      setFormValues(selectedRowData)
   
    }else{
      setFormValues({})
    
    }
  },[editIcon])

  
  const handleEditorChange = (editorContent) => {
    setEditorState(editorContent);
    setFormValues({...formValues,blog_body:editorContent})
    setHtmlContent(editorState.toHTML());
//ContentUtils.insertTextAfter(editorState,"hi");
}



  const closeDrawer = () => {
    
    refreshData();
    setEditIcon(false)
    setIsDrawerOpen(false);
  };
    const viewUser = (data)=>{
      setEditIcon(true)
      setSelectedRowData(data);
      setIsDrawerOpen(true);
    }

    async function uploadToCloudinary(formData) {
      try {
        isLoading(true);
        const response = await fetch('https://api.cloudinary.com/v1_1/ds262kt6h/video/upload', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        });
    
        const result = await response.json();
        return { url: result.url, public_id: result.public_id };
      } catch (err) {
        console.error('Error:', err);
        throw err;
      } finally {
        isLoading(false);
      }
    }

    const CreateBlog = async (selectedRowData)=>{
    
      if(loading){
        return false
      }
      const mergedObject = formValues


      console.log('formValues',mergedObject);
      console.log('html',htmlContent)
    
      
      const blog_title = mergedObject.hasOwnProperty("title");
      const blog_type = mergedObject.hasOwnProperty("type");
      const blog_category = mergedObject.hasOwnProperty("category");
      
      if(!blog_title){
        message.error('Blog Title is Required')
        return false
      }
      if(!blog_category){
        message.error('Blog Category is Required')
        return false
      }
      if(!blog_type){
        message.error('Blog Type is Required')
        return false
      }
      

      const blogfile = mergedObject.hasOwnProperty("blogfile");
      const blogbody = mergedObject.hasOwnProperty("blog_body");
     
      if(!selectedFile && !blogbody ){
        message.error('Blog Text/Blog video is Required')
        return false
      }
      if(blog_type == "video" && !selectedFile){
        message.error('File Is required')
        return false

      }

      if(editorState.isEmpty()){
        message.error('Blog Post Is Empty')
        return false
      }

      

      let uploadResult = null;
      let updatedURL= null;

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('upload_preset', 'ml_default');
        formData.append('cloud_name', 'ds262kt6h');
    
        try {
          uploadResult = await uploadToCloudinary(formData);
        } catch (error) {
          message.error('File upload failed');
          return false;
        }
      }

      const formData = new FormData();
      if (uploadResult) {
        formData.append('video_path', uploadResult.url);
        formData.append('filename', uploadResult.public_id);
        formData.append('type', 'video');
      } else {
        formData.append('type', 'text');
      }
      formData.append('title', mergedObject.title);
      formData.append('category', mergedObject.category);
      formData.append('blog_body', htmlContent);
    
      if (editIcon) {
        formData.append('_method', 'put');
        updatedURL = BLOG_POST + "/" + selectedRowData.id;
      } else {
        updatedURL = BLOG_POST;
      }
     

      saveRecords(updatedURL, formData,token).then(res => {
  
          isLoading(false)
          if (res.status =="Request was successful") {
           
              message.success(selectedRowData ? 'Blog Post Successfully Updates':'Blog Post Successfully Created')
              setFormValues({})
              closeDrawer()
              
          } else {
              // setError(res.error);
              message.info("Error encountered while creating blog post");
          }
          isLoading(false);
        }).catch(err => {
          console.log(err?.response?.data);
          if(err?.response?.data){
              message.error(err?.response?.data)
          }
        
          isLoading(false);
      });
      
     
      
     
    }
    const OpenDrawer = ()=>{
      setEditIcon(false)
      setIsDrawerOpen(true)
    }
    const onClose = () => setIsOpen(false);
    const handleDelete=(rowData)=>{
      setSelectedRowData(rowData)
     setIsOpen(true)
    }

    const _delete = ()=>{
      let formData =  new FormData()
      formData.append('_method', 'delete');
      let updatedURL = BLOG_POST + "/" + selectedRowData.id
     
      saveRecords(updatedURL, formData,token).then(res => {
        
        isLoading(false)
          if (res.status =="Request was successful") {
            
              message.success('Plan Successfully Deleted')
              refreshData()
              setIsOpen(false)
              
          } else {
              // setError(res.error);
              message.info("Error encountered while deleting plan");
          }
          isLoading(false);
        }).catch(err => {
          console.log(err?.response?.data);
          if(err?.response?.data){
              message.error(err?.response?.data)
          }
        
          isLoading(false);
      });
    }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            All BlogPost
          </Text>
          <Button variant='action' onClick={()=>OpenDrawer()}>Create New BlogPost</Button>
        </Flex>
       
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='secondaryGray.900'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                   
                    if (cell.column.Header === "First Name") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } 
                    
                 
                    else if (cell.column.Header == "#") {
                      data = (
                        <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='500'>
                        {cell.value}
                      </Text>
                      );
                    }
                   
                    else if (cell.column.Header === "Action") {
                      data = (
                        <Box style={{display:"flex",flexDirection:"row"}} >
                          <MdEdit style={{marginRight:5,cursor:'pointer'}} onClick={()=>viewUser(row.original)}/>
                          <MdDelete style={{cursor:'pointer'}} onClick={()=>handleDelete(row.original)}/>
                        </Box>
                      );
                    }
                  
                    else  {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>{editIcon ? 'Edit New BlogPost':'Create New BlogPost'}</DrawerHeader>
            <DrawerBody>

            <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog Title<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              value={formValues.title || ''}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Blog Title'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setFormValues({...formValues,title:event.target.value})}
            />
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog category<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.category || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Category"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,category:event.target.value})}
        >
            {category && category.length > 0 && category.map((d,index)=>{
              return <option value={d.category}>{d.category}</option>
            })}
        </Select>
            
       
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Blog type<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
            isRequired={true}
            variant="auth"
            value={formValues.type || ''}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Select Blog Type"
            mb="24px"
            fontWeight="500"
            size="lg"
            onChange={(event)=>setFormValues({...formValues,type:event.target.value})}
        >
            {blogType && blogType.length > 0 && blogType.map((d,index)=>{
              return <option value={d.blogtype}>{d.blogtype}</option>
            })}
        </Select>
      
        {/* {
          formValues.type == "text" &&
          <Textarea  value={formValues.blog_body || ''} onChange={(event)=>setFormValues({...formValues,blog_body:event.target.value})} placeholder="Write your blog post"></Textarea>
        } */}
        {
          formValues.type == "text" && 
          <div className="bg-white">

            <BraftEditor
                placeholder="Enter Message"
                value={formValues.blog_body || ''}
                onChange={handleEditorChange}
                language="en"
                id="editor"

            />
          </div>
        }
        {
          formValues.type == "video" &&
          <>
            <label>Select the video file</label><Input type="file" onChange={handleFileChange} />
            <label>{formValues?.filename}</label>
          </>
        }
          
        
            <Button
              style={{marginTop:30}}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={()=>CreateBlog(selectedRowData)}>
              {editIcon ?'Update Blog Post':'Create Blog Post'}
            </Button>
          </FormControl>
            

           
            
           
           
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose} >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmation</AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to proceed?</AlertDialogBody>
            <AlertDialogFooter>
              <Button  onClick={()=>onClose()}>Cancel</Button>
              <Button isLoading={loading} colorScheme="red" onClick={()=>_delete()}  ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
