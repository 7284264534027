/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue,Menu, MenuButton, MenuList,Button  } from "@chakra-ui/react";
import {MdArrowDropDown} from "react-icons/md";
import { useSelector } from 'react-redux';
export function SidebarLinks(props) {
  //   Chakra color mode
  const {userdetails} = useSelector((state)=>state.UserDetails)
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("elite.100", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("elite.100", "elite.100");
  let role_id = userdetails?.user?.roles[0].id;
  //console.log('roleID',role_id)
  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const isAuthorized = (authorizedRoles)=>{
    // console.log(authorizedRoles,route)
      if(authorizedRoles.includes(role_id)){
        return true
      }
      else{
       return false
      }
  }

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      
      if(isAuthorized(route?.authorization)){

          if (route.category) {
            
            return (
              <>
                <Text
                  fontSize={"md"}
                  color={activeColor}
                  fontWeight='bold'
                  mx='auto'
                  ps={{
                    sm: "10px",
                    xl: "16px",
                  }}
                  pt='18px'
                  pb='12px'
                  key={index}>
                  {route.name}
                </Text>
                {createLinks(route.items)}
              </>
            );
          
          
        } else if (
          route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl"
        ) {
          if (route.children && route.children.length > 0) {
            isAuthorized(route?.authorization,route)
            return (
              <Box key={index}>
                <Menu>
                  
                    <MenuButton
                      as={Button}
                      
                      key={index}
                      style={{
                        flex:1,
                        padding:0,
                        width:'100%',
                        backgroundColor:'#fff'
                      }}
                      
                    >
                      
                      {route.icon ? (
                        <HStack
                          spacing={
                            activeRoute(route.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py='5px'
                          ps='10px'
                        >
                          <Flex w='100%' alignItems='center' justifyContent='center'>
                            <Box
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me='18px'
                            >
                              {route.icon}
                            </Box>
                            <Text
                              me='auto'
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeColor
                                  : textColor
                              }
                              fontWeight={
                                activeRoute(route.path.toLowerCase())
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              {route.name}
                              
                            </Text>
                          </Flex>
                          <Box
                            h='36px'
                            w='4px'
                            bg={
                              activeRoute(route.path.toLowerCase())
                                ? brandColor
                                : "transparent"
                            }
                            borderRadius='5px'
                          />
                        </HStack>
                      ) : (
                        <HStack
                          spacing={
                            activeRoute(route.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py='5px'
                          ps='10px'
                        >
                          <Text
                            me='auto'
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight={
                              activeRoute(route.path.toLowerCase())
                                ? "bold"
                                : "normal"
                            }
                          >
                            {route.name}
                          </Text>
                          <Box
                            h='36px'
                            w='4px'
                            bg='brand.400'
                            borderRadius='5px'
                          />
                        </HStack>
                      )}
                    </MenuButton>
                
                
                  <MenuList>
                    
                    {createLinks(route.children)}
                  </MenuList>
                </Menu>

              </Box>
            );
          }
          else{
            if(route.name != "Sign In" && route.name != "Sign Up" && route.name != "Profile" ){
              return (
                <NavLink key={index} to={route.layout + route.path}>
                  {route.icon ? (
                    <Box>
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                        }
                        py='5px'
                        ps='10px'>
                        <Flex w='100%' alignItems='center' justifyContent='center'>
                          <Box
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeIcon
                                : textColor
                            }
                            me='18px'>
                            {route.icon}
                          </Box>
                          <Text
                            me='auto'
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : textColor
                            }
                            fontWeight={
                              activeRoute(route.path.toLowerCase())
                                ? "bold"
                                : "normal"
                            }>
                            {route.name}
                          </Text>
                        </Flex>
                        <Box
                          h='36px'
                          w='4px'
                          bg={
                            activeRoute(route.path.toLowerCase())
                              ? brandColor
                              : "transparent"
                          }
                          borderRadius='5px'
                        />
                      </HStack>
                    </Box>
                  ) : (
                    <Box>
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                        }
                        py='5px'
                        ps='10px'>
                        <Text
                          me='auto'
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : inactiveColor
                          }
                          fontWeight={
                            activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                          }>
                          {route.name}
                        </Text>
                        <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                      </HStack>
                    </Box>
                  )}
                </NavLink>
              );
            }
            
          }
          
        }
      }
      
    });
  };

  //  BRAND



  return createLinks(routes);
}

export default SidebarLinks;
