export const tableColumnsTopCreators = [
  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "First Name",
    accessor: "firstname",
  },
  {
    Header: "Last name",
    accessor: "lastname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Staff Role",
    accessor: "role",
  },
  
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
