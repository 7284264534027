/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
   DrawerHeader, 
   DrawerBody,
   Input,
   Spinner,
   Modal,
   
} from "@chakra-ui/react";


// Custom components

import Banner from "views/admin/checkin/components/Banner";
import TableTopCreators from "views/admin/checkin/components/TableTopCreators";
import HistoryItem from "views/admin/checkin/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/checkin/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/checkin/variables/tableColumnsTopCreators";
import {Alert, message} from 'antd'
import { saveRecords,fetchRecords } from "appRedux/actions";
import {useSelector,useDispatch} from 'react-redux'
import {BLOG_POST, CHECKIN, GET_ALL_PERMISSION, GET_CHECK_IN, STAFF} from '../../../constants/ServerUrl'
import { all } from "axios";
import WelcomeVoice from '../../../assets/sound/welcome-64347.mp3'
import WelcomeSound from '../../../assets/sound/completed.mp3'
import ErrorSound from '../../../assets/sound/wrong-answer.mp3'
import Logout from '../../../assets/sound/logout.mp3'
import LogoutVoice from '../../../assets/sound/goodbye.mp3'
import moment from "moment";
import Helpers from "util/Helpers";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [users, setUsers] = React.useState([]);
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
 const [blogPost,setBlogPost] = React.useState([])
 const [formValues, setFormValues] = React.useState({});
 const [checkin,isCheckIn] = useState('')
 const [loading,isLoading] = useState(false)
 const [resp ,isResp] = useState('')
 const [error ,isError] = useState('')
 const [startDate, setStartDate] = useState(Helpers.getSQLDateFromMoment(moment().startOf('day')));
 const [endDate, setEndDate] = useState(Helpers.getSQLDateFromMoment(moment().endOf('day')));
 const [totalCheckins,setTotalCheckins] = useState(0)
 
 
  React.useEffect(()=>{
    console.log('changed')
    getCheckins();

    
  },[endDate])
      const getCheckins = ()=>{
      
        fetchRecords(GET_CHECK_IN +'?startDate='+startDate+'&endDate='+endDate,token).then((res) => {
          console.log('resultsss',res.data)
          message.info('CheckIns Retrieved')
         
          setBlogPost(res.data.checkin)
          setTotalCheckins(res.data.checkin.length)
       }).catch(err => {
          message.error('CheckIn Retrieve Failed')
           console.log("error",err);
       });
      }
      const checkInUserPost = (userid)=>{
       
        const audioVoice = new Audio(WelcomeVoice);
        const audioSound = new Audio(WelcomeSound);
        const audioSoundError = new Audio(ErrorSound);
        const audioSoundLogout = new Audio(Logout);
        const audioSoundLogoutVoice = new Audio(LogoutVoice);

        
        let items = {userid:userid}
        isError('');
        isResp('')
        
        saveRecords(CHECKIN,items,token).then((res) => {
          console.log('res-checkin',res)
          isLoading(false)
          if(res.status =="Request was successful"){
            message.info(res.message)
            isResp(res.message)
            isCheckIn('')
            if(res.data == "Login User"){
              audioSound.play()
              audioVoice.play()
            }
            else if(res.data == "Logout User"){
              audioSoundLogout.play()
              audioSoundLogoutVoice.play()
            }
           getCheckins();
            
            setTimeout(()=>{
              audioSound.pause()
              audioVoice.pause() 
            },3000)
            
          }
          
          
          
          
          
       }).catch(err => {
        isLoading(false)
          message.error(err?.response?.data?.message)
           console.log("error",err?.response?.data?.message);
       
           isError(err?.response?.data?.message)
           audioSoundError.play();
       });
      }
      const checkInUser = (event)=>{
        isCheckIn(event.target.value)
        if(event.target.value.length >1){
          isLoading(true)
          checkInUserPost(event.target.value);
        }
      }
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
               Scan in Users
              </Text>
            
            </Flex>
            <Input
              isRequired={true}
              value={checkin}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Checkin-user-id'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={checkInUser}
              autoFocus
            />
           {loading && <Spinner/> } 
           {!loading && resp &&   <Alert message={resp} type="success" showIcon />}
           {!loading && error &&   <Alert message={error} type="error" showIcon />}

        </Card>
        
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={blogPost}
              columnsData={tableColumnsTopCreators}
              refreshData={getCheckins}
              setEndDate={setEndDate}
              setStartDate={setStartDate}

               tableCount={totalCheckins}
            />
          </Card>
          
        </Flex>
      </Grid>
     
      {/* Delete Product */}
    </Box>
  );
}
