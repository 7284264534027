export const tableColumnsTopCreators = [

  {
    Header: "SN",
    accessor: "SN",
  },
  {
    Header: "Plan Title",
    accessor: "plan_title",
  },
  {
    Header: "Sessions",
    accessor: "plan_duration",
  },
  {
    Header: "Plan Amount",
    accessor: "plan_amount",
  },
  
  {
    Header: "Status",
    accessor: "plan_status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];
