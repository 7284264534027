import React from "react";

// Chakra imports
import { Flex, useColorModeValue,Heading,Button,HStack,Tag } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import { useSelector,useDispatch } from "react-redux";
export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const textColor = useColorModeValue("secondarygray.900", "white");
  const {userdetails} = useSelector((state)=>state.UserDetails)
  // console.log('userDetailsNav',userdetails?.user?.roles[0].id)
  return (
    <Flex align='center' direction='column'>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <Heading color={textColor} fontSize='20px' mb='20px'>
            Elite Fitness Adminer
            
      </Heading>
     
      <HStack spacing={4} marginBottom={'3'}>
        <Tag size={'md'}  variant='solid' colorScheme="linkedin">
             {userdetails?.user?.roles[0].name}
          </Tag>
      </HStack>
      <HSeparator mb='10px' />
    </Flex>
  );
}

export default SidebarBrand;
