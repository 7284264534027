
import axios from '../../util/Api';

export const fetchRecords = (url,token=null) => {
  //let token = ""
 
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
   axios.defaults.headers.common['Accept'] = "application/json";
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = "79867";

  return new Promise((resolve, reject) => {
    axios.get(url).then(({data}) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    });
  });
};

export const saveRecords = (url, data,token=null) => {
  // let token = JSON.parse(localStorage.getItem("token"));
  //  axios.defaults.headers.common['Accept'] = " application/json";
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = "79867";
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(({data}) => {

      resolve(data);

    }).catch(err => {
      reject(err);
      console.log(err);

    });
  });


};





