import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const userDetailSlice = createSlice({
  name: "userdetails",
  initialState: {
    userdetails: null
  },
  reducers: {
    setUserDetails(state, action) {
        
        state.userdetails = action.payload
      },
      setFirstNameAndLastName: (state, action) => {
        const { firstname, lastname } = action.payload;
        state.userdetails.profile.firstname = firstname;
        state.userdetails.profile.lastname = lastname;
      },
      clearUserDetails:(state)=>{
        state.userdetails = null
      }
    
  }
})

export const { setUserDetails,setFirstNameAndLastName ,clearUserDetails} = userDetailSlice.actions
export default userDetailSlice.reducer