export const tableColumnsTopCreators = [
  {
    Header: "SN",
    accessor: "",
  },
  {
    Header: "User ID",
    accessor: "user_id",
  },
  {
    Header: "Full name",
    accessor: "firstname",
  },
  {
    Header: "CheckIn Time",
    accessor: "checkin_time",
  },
  {
    Header: "CheckOut Time",
    accessor: "checkout_time",
  },
  

];
